import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, keywords, title, image }) => {
  const data = useStaticQuery(graphql`
    query DefaultSEOQuery {
      site {
        siteMetadata {
          siteUrl
          title
          description
          author
        }
      }
    }
  `)
  const {
    title: siteTitle,
    description: siteDescription,
    author,
    siteUrl,
  } = data.site.siteMetadata

  const metaDescription = description || siteDescription
  const metaTitle = title || siteTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      titleTemplate={`${siteTitle} — ${siteDescription}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
      ]
        .concat(
          image
            ? {
                name: `og:image`,
                content: siteUrl + image,
              }
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [
    "ali",
    "karagoz",
    "ali karagoz",
    "blog",
    "photoblog",
    "photography",
    "software developer",
    "angers",
    "france",
    "indie",
    "freelance"
  ],
}

export default SEO
