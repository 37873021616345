import React from "react"
import Layout from "../components/Layout"
import PostInList from "../components/PostInList"
import SEO from "../components/SEO"
import { graphql, Link } from "gatsby"

const BlogListTemplate = ({ data, pageContext }) => {
  const { edges: posts } = data.allMarkdownRemark
  const image = posts[0].node.frontmatter.images[0].childImageSharp.original.src
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? "/" : (currentPage - 1).toString()
  const nextPage = (currentPage + 1).toString()
  return (
    <Layout>
      <SEO image={image} />
      <div className="blog-posts">
        {posts.map(({ node: post }) => {
          return <PostInList post={post} />
        })}
        <div className="pagination">
          {!isFirst && (
            <Link className="prev" to={"/" + prevPage + "/"} rel="prev">
              Previous
            </Link>
          )}
          ({currentPage}/{numPages})
          {!isLast && (
            <Link className="next" to={"/" + nextPage + "/"} rel="next">
              Next
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default BlogListTemplate

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostNode
        }
      }
    }
  }
`
