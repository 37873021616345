import React from "react"
import { Link } from "gatsby"

const Layout = ({ children }) => {
  const socialLinks = [
    { account: "Twitter", link: "https://twitter.com/alikaragoz" },
    { account: "Instagram", link: "https://instagram.com/alikaragoz" },
    { account: "Github", link: "https://github.com/alikaragoz" },
    { account: "Email", link: "mailto:mail@alikaragoz.net" },
  ]
  return (
    <div>
      <header>
        <h1>
          <Link to="/" title="Go to Homepage">
            Ali Karagoz
          </Link>
        </h1>
        <div className="about">
          A Software Developer living in Angers, France. I sometimes take
          photos.
        </div>
        <div className="links">
          <Link to="/archive/">Archive</Link>
          <span>•</span>
          {socialLinks
            .map(o => {
              return <a href={o.link}>{o.account}</a>
            })
            .reduce((accu, elem) => {
              return accu === null ? [elem] : [...accu, <span>•</span>, elem]
            }, null)}
        </div>
      </header>
      <main>{children}</main>
    </div>
  )
}

export default Layout
